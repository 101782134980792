import 'ress';
import 'core-js/stable';
import '../scss/style.scss';

// window.jQuery = window.$ = require('jquery'); // jquery使いたい時

document.addEventListener('DOMContentLoaded', () => {
	const hekinanList01 = document.querySelector('#js-hekinan-list01');
	const hekinanlist01s = [
		{
			link: '#',
			img: 'images/shop/001.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、',
			pickup: '<span class="el_pickup">pick up!</span>',
		},
		{

			img: 'images/shop/002.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら',
			pickup: '<span class="el_pickup">pick up!</span>',
		},
	];
	for (let i = 0; i < hekinanlist01s.length; i++) {
		const {
			link, name, text, img, pickup,
		} = hekinanlist01s[i];

		let content = `
    <div class="bl_shopList01">
      <div class="bl_shopList_body">
			${pickup}
        <img src="${img}" width="100%">
        <h3>${name}</h3>
        <p>${text}</p>`;

		if (link) {
			content += `
      <a href="${link}" target="_blank">お店の最新情報はコチラ<span><img src="images/icon/targetBlank.png" width="16px"></span></a>`;
		}
		content += `
      </div>
    </div>`;
		hekinanList01.insertAdjacentHTML('beforeend', content);
	}
});

document.addEventListener('DOMContentLoaded', () => {
	const hekinanList = document.querySelector('#js-hekinan-list');
	const hekinanlists = [
		{
			link: '#',
			img: 'images/shop/001.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',

		},
		{
			link: '#',
			img: 'images/shop/002.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',
		},
		{
			img: 'images/shop/003.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',
		},
		{
			link: '#',
			img: 'images/shop/004.jpg',
			name: '事業所名',
			text: ' あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',
		},
		{
			link: '#',
			img: 'images/shop/005.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',
		},
		{

			img: 'images/shop/006.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',
		},
		{
			link: '#',
			img: 'images/shop/001.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',

		},
		{
			link: '#',
			img: 'images/shop/002.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',
		},
		{
			link: '#',
			img: 'images/shop/003.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',
		},
		{
			link: '#',
			img: 'images/shop/004.jpg',
			name: '事業所名',
			text: ' あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',
		},
		{
			link: '#',
			img: 'images/shop/005.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',
		},
		{
			link: '#',
			img: 'images/shop/006.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',
		},
		{

			img: 'images/shop/006.jpg',
			name: '事業所名',
			text: 'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。',
		},

	];
	for (let i = 0; i < hekinanlists.length; i++) {
		const {
			link, name, text, img,
		} = hekinanlists[i];
		let content = `
    <div class="bl_shopList">
      <div class="bl_shopList_body">
        <img src="${img}" width="100%">
        <h3>${name}</h3>
        <p>${text}</p>`;
		if (link) {
			content += `
      <a href="${link}" target="_blank">お店の最新情報はコチラ<span><img src="images/icon/targetBlank.png" width="16px"></span></a>`;
		}
		content += `
      </div>
    </div>`;

		hekinanList.insertAdjacentHTML('beforeend', content);
	}
});
